export const useSeoStore = defineStore('seo', {
    state: () => ({
        seoAttributes: {
            title: '',
            description: '',
            image: '',
        },
        notificationCount: 0
    }),

    actions: {
        setSeoData(data) {
            this.seoAttributes.title = data.title || '';
            this.seoAttributes.description = data.description || '';
            this.seoAttributes.image = data.image || '';
            this.triggerHeadUpdate();
        },

        setNotificationCount(count) {
            this.notificationCount = count;
            this.triggerHeadUpdate();
        },

        triggerHeadUpdate() {
            let {title, description, image} = this.seoAttributes;

            let notificationCount = this.notificationCount;
            if (notificationCount > 0) {
                title = `(${notificationCount}) ${title}`;
            }

            let payload = {}

            if(title) {
                payload.title = title;
                payload.ogTitle = title;
            }

            if(description) {
                payload.description = description;
                payload.ogDescription = description;
            }

            if(image) {
                payload.ogImage = image;
            }

            useSeoMeta(payload)
        }

    },
})