import * as amplitude from "@amplitude/analytics-browser";

// We provide the Amplitude instance to the
// context but is later initialized  in alalytics.ts
export default defineNuxtPlugin(() => {
  return {
    provide: {
      amplitude,
    },
  };
});
