<script setup lang="ts">
const consentGiven = ref(false);
type ConsentValue = "allow-all" | "required-only" | "decline-all" | null;
// Cookie to store user consent
// const consentValue = useCookie('userConsent');
const consentValue = ref<ConsentValue>(null);

// Check if user already made a choice
if (consentValue.value) {
  consentGiven.value = true;
}

// Handle "Allow all" click
const handleAllowAll = () => {
  // Set the cookie to store the user's consent for all cookies
  consentValue.value = "allow-all";
  consentGiven.value = true;
  saveConsent();

  // Load Amplitude plugin
  initializeAmplitude();
};

// Handle "Required only" click
const handleRequiredOnly = () => {
  // Set the cookie to store the user's consent for required cookies only
  consentValue.value = "required-only";
  consentGiven.value = true;
  saveConsent();
};

// Handle "Decline all" click
const handleDeclineAll = () => {
  // Set the cookie to store the user's decision to decline all cookies
  consentValue.value = "decline-all";
  consentGiven.value = true;
  saveConsent();
};

function saveConsent() {
  useDomainCommunication().crossDomainUpdate("userConsent", consentValue.value);
  // window.localStorage.setItem("userConsent", consentValue.value);
}

onMounted(() => {
  // @ts-expect-error
  consentValue.value = window.localStorage.getItem("userConsent");
  if (!consentValue.value) {
    return;
  }

  if (consentValue.value === "allow-all") {
    handleAllowAll();
  }

  if (consentValue.value === "required-only") {
    handleRequiredOnly();
  }

  if (consentValue.value === "decline-all") {
    handleDeclineAll();
  }
});
</script>

<template>
  <div
    v-if="!consentGiven"
    class="fixed bottom-4 left-4 right-4 bg-black text-white z-50 rounded-2xl p-6 flex justify-between items-center flex-wrap gap-4"
  >
    <div class="flex gap-2 items-start lg:items-center">
      <span class="lg:order-2"
        >This website requires cookies to function properly.
        <!--                <button class="text-purple-light">See what cookies</button>-->
        <NuxtLink to="/articles/privacy-policy" class="text-purple-light"
          >See Privacy policy</NuxtLink
        >.</span
      >
      <IconsEvangelistIcon class="w-4 h-auto shrink-0 lg:order-1" />
    </div>
    <div class="flex gap-4 w-full lg:w-auto">
      <button
        class="btn bg-purple-light text-black hover:bg-black hover:text-white flex-grow lg:w-36"
        @click="handleAllowAll"
      >
        Allow all
      </button>
      <button
        class="btn bg-gray-400 text-black hover:bg-black hover:text-white flex-grow lg:w-36"
        @click="handleDeclineAll"
      >
        Decline all
      </button>
    </div>
  </div>
</template>

