export default defineNuxtRouteMiddleware((to, from) => {
    const config = useRuntimeConfig().public;
    const WHITELISTED_DOMAINS = config.WHITELISTED_DOMAINS;

    const currentDomain = window.location.origin;

    if (WHITELISTED_DOMAINS.includes(currentDomain)) {
        return true;
    }

    const allowedRoutesForGuestDomains = ['/', '/not-allowed', '/tos', '/privacy'];

    if (allowedRoutesForGuestDomains.includes(to.path)) {
        return true;
    }
});