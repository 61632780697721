import revive_payload_client_4sVQNw7RlN from "/github/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/github/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/github/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/github/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/github/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/github/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/github/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/github/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/github/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/github/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import amplitude_client_RJajK1QYBz from "/github/workspace/src/plugins/amplitude.client.ts";
import app_insights_h9TQ7RBX1K from "/github/workspace/src/plugins/app-insights.ts";
import event_bus_lrIMUlWjzF from "/github/workspace/src/plugins/event-bus.js";
import prototypes_GSu5VWf6jF from "/github/workspace/src/plugins/prototypes.js";
import vue_tippy_ZTosugnBbO from "/github/workspace/src/plugins/vue-tippy.js";
import vue_toastification_nbRgz4qVB7 from "/github/workspace/src/plugins/vue-toastification.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  amplitude_client_RJajK1QYBz,
  app_insights_h9TQ7RBX1K,
  event_bus_lrIMUlWjzF,
  prototypes_GSu5VWf6jF,
  vue_tippy_ZTosugnBbO,
  vue_toastification_nbRgz4qVB7
]