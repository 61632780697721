// const {$emit, $on, $off} = useNuxtApp()
import mitt from "mitt";

const emitter = mitt();

export function globalEmit(event: any, args: any = null) {
  emitter.emit(event, args);
}

export function globalOn(event: any, callback: any) {
  if (!callback) callback = () => {};

  emitter.on(event, callback);
}

export function globalOff(event: any, callback: any) {
  emitter.off(event, callback);
}
