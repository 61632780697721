export function removeReactivity(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function transformToPercentage(value) {
    value = (value * 100).toFixed(1);

    if (value == 100)
        value = 100;

    return `${value}%`;
}

export function freezeBody() {
    document.body.classList.add('freeze');
}

export function unfreezeBody() {
    document.body.classList.remove('freeze');
}

export function isChromeOnIOS() {
    // Check if the user agent contains 'CriOS' indicating Chrome on iOS
    const userAgent = navigator.userAgent;
    return /CriOS/i.test(userAgent) && /iPhone|iPad|iPod/i.test(userAgent);
}