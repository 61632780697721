<template>
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="fill: currentColor">
        <g clip-path="url(#clip0_567_425)">
            <path d="M8.00033 8.0013C9.47366 8.0013 10.667 6.80797 10.667 5.33464C10.667 3.8613 9.47366 2.66797 8.00033 2.66797C6.52699 2.66797 5.33366 3.8613 5.33366 5.33464C5.33366 6.80797 6.52699 8.0013 8.00033 8.0013ZM8.00033 9.33464C6.22033 9.33464 2.66699 10.228 2.66699 12.0013V13.3346H13.3337V12.0013C13.3337 10.228 9.78033 9.33464 8.00033 9.33464Z" />
        </g>
        <defs>
            <clipPath id="clip0_567_425">
                <rect width="16" height="16"/>
            </clipPath>
        </defs>
    </svg>
</template>
