import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const appInsightsString = config.public.APP_INSIGHTS_CONNECTION_STRING;

  if (appInsightsString) {
    const defaultStore = useDefaultStore();
    const user = defaultStore.user;

    const appInsights = new ApplicationInsights({
      config: {
        connectionString: appInsightsString,
        /* ...Other Configuration Options... */
      },
    });

    appInsights.loadAppInsights();

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
      appInsights.trackException(error as any, {
        //instance: instance?.toString(),
        info: info,
        userId: user.userId,
        twitterHandle: user.twitterHandle,
      });
    };
  }
});
