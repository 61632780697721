export default defineNuxtPlugin(nuxtApp => {

    Array.prototype.pull = function (item) {
        let index = this.indexOf(item);
        if (index > -1) {
            this.splice(index, 1);
            return index;
        } else {
            return -1;
        }
    };

    window.isNumber = (value, strict = false) => {
        if (strict) {
            return typeof value === 'number' && !isNaN(value);
        } else {
            return !isNaN(parseFloat(value)) && isFinite(value);
        }
    }

    window.isUndefined = (value) => {
        return typeof value === 'undefined';
    }

    window.isNull = (value) => {
        return value === null;
    }

})