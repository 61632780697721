import Toast from "vue-toastification";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(Toast, {
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        hideProgressBar: true,
        position: "bottom-right",
        timeout: 3000,
    });
})
