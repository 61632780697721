import validate from "/github/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import amplitude_45global from "/github/workspace/src/middleware/amplitude.global.js";
import check_45domain_45global from "/github/workspace/src/middleware/checkDomain.global.js";
import manifest_45route_45rule from "/github/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  amplitude_45global,
  check_45domain_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}