type Head = {
  title?: string;
  description?: string;
  image?: string;
};

export function setSeoMeta(h: Head) {
  const head: Head = h;

  if (h.title) {
    head.title = h.title;
  }

  if (h.description) {
    head.description = h.description;
  }

  if (h.image) {
    head.image = h.image;
  }

  useSeoStore().setSeoData(head);
}

export function setNotificationCount(count: number) {
  useSeoStore().setNotificationCount(count);
}

