<script setup lang="ts">
const publicConfig = useRuntimeConfig().public;

const currentDomain = computed(() => {
  return window.location.origin;
});

const isGuestDomain = computed(() => {
  return publicConfig.GUEST_DOMAINS.includes(currentDomain.value);
});

useBodyFreeze();
useFixedFullscreen();
</script>
<template>
  <div class="font-semibold">
    <!-- @vue-expect-error For Some wierd reason this fails be read as computed-->
    <LazyMarinadeLanding v-if="isGuestDomain" />

    <NuxtLayout v-else>
      <NuxtPage />
    </NuxtLayout>
    <!-- @vue-expect-error For Some wierd reason this fails be read as computed-->
    <CookieBar v-if="isGuestDomain" />
  </div>
</template>
