export function clearTokens() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
}

export function setTokens(accessToken, refreshToken) {
  if (!accessToken) {
    localStorage.removeItem("access_token");
  } else {
    localStorage.setItem("access_token", accessToken);
  }

  if (!refreshToken) {
    localStorage.removeItem("refresh_token");
  } else {
    localStorage.setItem("refresh_token", refreshToken);
  }
}

export function getTokens() {
  const accessToken = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  return { accessToken, refreshToken };
}

export function getAccessToken() {
  return localStorage.getItem("access_token");
}
