<template>
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="fill:currentColor">
        <g clip-path="url(#clip0_567_422)">
            <path d="M11.9997 7.33464V8.66797H14.6663V7.33464H11.9997ZM10.6663 11.7413C11.3063 12.2146 12.1397 12.8413 12.7997 13.3346C13.0663 12.9813 13.333 12.6213 13.5997 12.268C12.9397 11.7746 12.1063 11.148 11.4663 10.668C11.1997 11.028 10.933 11.388 10.6663 11.7413ZM13.5997 3.73464C13.333 3.3813 13.0663 3.0213 12.7997 2.66797C12.1397 3.1613 11.3063 3.78797 10.6663 4.26797C10.933 4.6213 11.1997 4.9813 11.4663 5.33464C12.1063 4.85464 12.9397 4.23464 13.5997 3.73464ZM2.66634 6.0013C1.93301 6.0013 1.33301 6.6013 1.33301 7.33464V8.66797C1.33301 9.4013 1.93301 10.0013 2.66634 10.0013H3.33301V12.668H4.66634V10.0013H5.33301L8.66634 12.0013V4.0013L5.33301 6.0013H2.66634ZM10.333 8.0013C10.333 7.11464 9.94634 6.31464 9.33301 5.76797V10.228C9.94634 9.68797 10.333 8.88797 10.333 8.0013Z" />
        </g>
        <defs>
            <clipPath id="clip0_567_422">
                <rect width="16" height="16"/>
            </clipPath>
        </defs>
    </svg>
</template>
