export function useBodyFreeze() {
    const updateBodyFreeze = () => {
        const freezeElements = document.querySelectorAll('.js-freeze-body');
        if (freezeElements.length > 0) {
            document.body.classList.add('body-freeze'); // Add a class to freeze the body
        } else {
            document.body.classList.remove('body-freeze'); // Remove the class to unfreeze
        }
    };

    let observer;

    onMounted(() => {
        // Set up the MutationObserver
        observer = new MutationObserver(updateBodyFreeze);
        observer.observe(document.body, { childList: true, subtree: true });
        updateBodyFreeze(); // Initial check
    });

    onBeforeUnmount(() => {
        if (observer) observer.disconnect();
    });
}